// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-deelnemen-js": () => import("./../../../src/pages/deelnemen.js" /* webpackChunkName: "component---src-pages-deelnemen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tips-js": () => import("./../../../src/pages/tips.js" /* webpackChunkName: "component---src-pages-tips-js" */),
  "component---src-pages-uploaden-js": () => import("./../../../src/pages/uploaden.js" /* webpackChunkName: "component---src-pages-uploaden-js" */)
}

